<template>
  <div
    id="vip"
    class="pt-56"
  >
    <Toolbar>
      <!-- 條款與規則 btn -->
      <v-btn
        slot="btn"
        to="vip-rules"
        text
        class="title--text caption"
      >
        {{ $t('myCenter.termsnRules') }}
      </v-btn>
    </Toolbar>

    <!-- TABS -->
    <v-tabs
      v-model="activeTab"
      height="40"
      background-color="transparent"
      color="primary"
      grow
    >
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        class="subtitle-1 font-weight-medium"
      >
        {{ tab }}
      </v-tab>
    </v-tabs>
    <v-divider class="divider" />

    <!-- COMTENT -->
    <component
      :is="activeComponent"
      :vip-list="vipList"
      @change-tab="changeTabActive"
    />

    <!-- DIALOG -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'

import Toolbar from '@/components/base/Toolbar'
import Details from './Details'
import Prerogative from './Prerogative'

export default {
  components: {
    Toolbar,
    Prerogative,
    Details,
  },

  data: () => ({
    activeTab: 0, // 特權 0 || 詳情 1
    vipList: [], // 所有 vip 資料
  }),

  computed: {
    ...mapState({
      nowVip: state => state.vip.now,
      nextVip: state => state.vip.next,
      vipProgress: state => state.vip.progress,
    }),

    tabs() {
      return [
        this.$t('myCenter.myPrivileges'),
        this.$t('myCenter.moreVipDetail'),
      ]
    },

    activeComponent() {
      return this.activeTab === 0 ? 'Prerogative' : 'Details'
    },
  },

  mounted() {
    this.getVipList()
    this.set_toolbar_title(this.$t('myCenter.myVip'))
    this.getUserVipData()
  },

  methods: {
    ...mapActions(['get_user_vip', 'set_toolbar_title', 'get_vip_list']),

    getUserVipDataSuccess() {},
    getUserVipDataFail() {},

    /**
     * 取得目前使用者 vip 資料 (目前/下一等級/進度) 存 vuex
     * @date 2021-04-19
     */
    async getUserVipData() {
      const res = await this.get_user_vip()
      resStatus(res, this.getUserVipDataSuccess, this.getUserVipDataFail)
    },

    /**
     * 切換 tab 頁籤 for component
     * @date 2021-04-19
     * @param {Number} tab  特權 0 || 詳情 1
     */
    changeTabActive(tab) {
      this.activeTab = tab
    },

    /**
     * 取得所有 vip 資料 (成功)
     * @date 2021-04-19
     * @param {object} {vip} data.vip vip 所有資料
     */
    getVipListSuccess({ vip }) {
      this.vipList = vip
    },

    /**
     * 取得所有 vip 資料
     * @date 2021-04-19
     */
    async getVipList() {
      const res = await this.get_vip_list()
      resStatus(res, this.getVipListSuccess)
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-container>
      <div class="text-center caption title--text font-weight-medium">
        {{ vipProgress.progress }}%
      </div>

      <v-row>
        <!-- Progress -->
        <v-col
          cols="12"
          class="userInfo__progress vip d-flex align-center py-0 px-10"
          :class="[{'pb-4': !nextVip}]"
        >
          <!-- 目前等級 icon -->
          <v-img
            v-if="nowVip.logo"
            class="vip__now"
            :src="baseImgUrl + nowVip.logo"
            max-height="28"
            max-width="28"
          />
          <!-- 進度條 -->
          <v-progress-linear
            class="mx-1 vip__progress"
            rounded
            :value="vipProgress.progress"
            color="primary"
            height="6"
          />
          <!-- 下一等級 icon -->

          <v-img
            v-if="nextVip"
            class="vip__next"
            :src="baseImgUrl + nextVip.logo"
            max-height="28"
            max-width="28"
          />
        </v-col>

        <!-- vip Description -->
        <v-col
          v-if="nextVip"
          cols="12 text-left pb-4 subtitle-2 px-10 pt-0 caption"
        >
          <ul class="pl-0">
            <!-- 當前存款 -->
            <li>
              <span class="title--text">
                {{ $t('myCenter.nowDeposit') }}
                {{ `(${$t('myCenter.dollar')})` }}
              </span>
              <span class="subtitle-1 primary--text font-weight-medium">
                {{ vipProgress.deposit_amount }}
              </span>
              <!-- 當前/升級 -->
              <span class="hint--text">
                ({{ vipProgress.deposit_amount }} /
                {{ nextVip.deposit_amount }} )
              </span>
            </li>
            <!-- 當前流水 -->
            <li>
              <span class="title--text">
                {{ $t('myCenter.nowTurnover') }}
                {{ `(${$t('myCenter.dollar')})` }}
              </span>
              <span class="subtitle-1 primary--text font-weight-medium">
                {{ vipProgress.bet_amount }}
              </span>

              <!-- 當前/升級 -->
              <span class="hint--text">
                ({{ vipProgress.bet_amount }} / {{ nextVip.bet_amount }} )
              </span>
            </li>
          </ul>
        </v-col>
      </v-row>

      <!-- Bonus -->
      <v-row class="px-4 bonus">
        <!-- 每日提款次数 -->
        <v-col
          cols="6"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="secondary--text text-h6">
                {{ nowVip.withdrawal_daily_count }}
              </span>
              <span> {{ $t('myCenter.times') }}</span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('myCenter.withdrawalDailyCount') }}
            </div>
          </div>
        </v-col>

        <!-- 每日提款额度 -->
        <v-col
          cols="6"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="secondary--text text-h6">
                {{ nowVip.withdrawal_daily_amount }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('myCenter.withdrawalDailyLimit') }}
            </div>
          </div>
        </v-col>

        <!-- 升级礼金 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="secondary--text text-h6">
                {{ nowVip.upgrade_bonus }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              <a
                href="javascript:;"
                class="link--text"
                @click="$emit('change-tab', 1)"
              >
                {{ $t('myCenter.upgradeBonus') }}
              </a>
            </div>
          </div>
        </v-col>

        <!-- 生日礼金 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="secondary--text text-h6">
                {{ nowVip.birth_bonus }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              <a
                href="javascript:;"
                class="link--text"
                @click="$emit('change-tab', 1)"
              >
                {{ $t('myCenter.birthdayBonus') }}
              </a>
            </div>
          </div>
        </v-col>

        <!-- 每月免费红包 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="secondary--text text-h6">
                {{ nowVip.monthly_bonus }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              <a
                href="javascript:;"
                class="link--text"
                @click="$emit('change-tab', 1)"
              >
                {{ $t('myCenter.monthlyBonus') }}
              </a>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="px-0 pb-0"
        >
          <v-divider class="divider" />
        </v-col>
      </v-row>

      <!-- 返水比例 -->
      <v-row class="px-1 rebates">
        <v-col
          cols="12"
          class="title--text mb-4"
        >
          {{ $t('myCenter.vipTurnover') }}
        </v-col>
      </v-row>

      <!-- 返水項目 -->
      <v-row class="px-4 mt-0 pb-4">
        <v-col
          v-for="game in nowVip.rebates"
          :key="game.name"
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded d-flex flex-column align-center justify-center h-80px"
          >
            <div class="rebates__percentage">
              <span class="primary--text text-h6"> {{ game.value }}% </span>
            </div>

            <div class="rebates__description titile--text">
              {{ game.name }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapGetters(['baseImgUrl']),

    ...mapState({
      nowVip: state => state.vip.now,
      nextVip: state => state.vip.next,
      vipProgress: state => state.vip.progress,
    }),
  },
}
</script>

<style lang="scss" scoped>
.vip {
	&__next {
		filter: grayscale(90%);
	}
}

ul {
	list-style: none;
}

.h-80px {
	height: 80px;
}
</style>
